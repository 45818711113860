import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import emitter from '../services/emitter';
import classNames from 'classnames';
import { VideoTimestampState } from '../atoms';
import { useRecoilState, useRecoilValue } from 'recoil';
import { formatDuration } from 'date-fns';
import React from 'react';

interface TranscriptDataProps {
    data: any;
}

export const TranscriptData: FC<TranscriptDataProps> = ({ data }) => {
    const [videoTimestamp, setVideoTimestamp] = useRecoilState(VideoTimestampState);
    const sentenceRefs = useRef<{ [key: number]: React.RefObject<HTMLSpanElement> }>({});
    const currentScrollTime = useRef<number>(0);

    let paragraphs = useMemo(() => {
        if (!data) return [];
        return data.results.channels[0].alternatives[0].paragraphs.paragraphs;
    }, [data]);

    useEffect(() => {
        scrollToSentence(videoTimestamp);
    }, [videoTimestamp]);

    const handleSeek = (time: number) => {
        setVideoTimestamp(time);
        emitter.emit("video.seek", time);
        scrollToSentence(time);
    }

    const scrollToSentence = useCallback((time: number) => {
        const closestStartTime = Object.keys(sentenceRefs.current).reduce((prev, curr) =>
            Math.abs(curr as any - time) < Math.abs(prev as any - time) ? curr : prev
        );
    
        if (parseFloat(closestStartTime) < time && parseFloat(closestStartTime) != currentScrollTime.current) {
            sentenceRefs.current[parseFloat(closestStartTime)]?.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
            currentScrollTime.current = parseFloat(closestStartTime);
        }
    }, [sentenceRefs]);

    let n = 5;

    const formatTime = (seconds: number) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds - (hours * 3600)) / 60);
        const remainingSeconds = seconds - (hours * 3600) - (minutes * 60);

        let timeString = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toFixed(0).padStart(2, '0')}`;
        return timeString;
    }

    return (
        <div className="flex flex-col justify-center text-sm items-start space-y-2">
            {paragraphs.map((paragraph: any, pindex: number) => {
                let sentenceCount = 0;
                return paragraph.sentences.map((sentence: any, sindex: number) => {
                    sentenceCount++;
                    let isCurrent = sentence.start <= videoTimestamp && sentence.end >= videoTimestamp;
                    let formattedTime = formatTime(sentence.start);
                    sentenceRefs.current[sentence.start] = React.createRef();

                    return (
                        <React.Fragment key={sindex}>
                            {sentenceCount % n === 0 && <small className="text-[0.6rem] p-1 text-gray-500">{formattedTime}</small>}
                            <span 
                                ref={sentenceRefs.current[sentence.start]}
                                className={classNames("inline-flex flex-wrap p-1 font-medium text-slate-700 dark:text-slate-200 rounded-md hover:bg-blue-50 dark:hover:bg-slate-700 cursor-pointer", {"bg-blue-100 dark:bg-slate-600": isCurrent})}
                                onClick={() => handleSeek(sentence.start)}>
                                {sentence.text}
                                <br/>
                            </span>
                        </React.Fragment>
                    )
                })
            })}
        </div>
    );
};

export default TranscriptData;
import React from 'react';
import { FC } from 'react';
import { useRecoilState } from 'recoil';
import { citationLoadingState } from '../../../atoms';
import TinyLoadingIndicator from '../../TinyLoadingIndicator';

interface InlineCitationProps {
  id: string;
  citation: string;
  onClick?: () => void;
}


export const InlineCitation: FC<InlineCitationProps> = ({ id, citation, onClick }) => {
  const [loadingState, setLoadingState] = useRecoilState < { id: string, loading: boolean }>(citationLoadingState);

  return (
    loadingState.loading && loadingState.id === id ? 
    <span className="text-xs p-0.5 rounded">
      <TinyLoadingIndicator />
    </span>
    :
    <span onClick={onClick} className="text-blue-500 dark:text-blue-300 hover:underline hover:bg-slate-200 cursor-pointer text-xs dark:bg-slate-600 bg-slate-100 p-0.5 rounded">
      <a>{citation}</a>
    </span>
  );
};

export default InlineCitation;
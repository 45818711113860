import { FC } from 'react';

interface LoadingIndicatorProps {
  color?: string;
}

export const LoadingIndicator: FC<LoadingIndicatorProps> = ({ color = 'text-black' }) => {
  return (
    <div className={`animate-spin inline-block w-6 h-6 border-[3px] border-current dark:text-white border-t-transparent ${color} rounded-full`} role="status" aria-label="loading">
        <span className="sr-only">Loading...</span>
    </div>
  );
};

export default LoadingIndicator;
import React, { useRef, useEffect } from 'react';

interface FlexibleTextareaProps {
  id: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
}

const FlexibleTextarea: React.FC<FlexibleTextareaProps> = ({
  id,
  value,
  onChange,
  placeholder,
  className,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    adjustTextareaHeight();
  }, [value]);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = value ? `${textareaRef.current.scrollHeight}px` : "1.5rem" 
    }
  };

  const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  return (
    <textarea
      id={id}
      ref={textareaRef}
      value={value}
      onChange={handleTextareaChange}
      placeholder={placeholder}
      style={{lineHeight: "1.5rem"}}
      className={`w-full bg-transparent focus:outline-none rounded-md resize-none ${className}`}
    />
  );
};

export default FlexibleTextarea;

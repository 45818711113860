import React from 'react';
import classNames from 'classnames';
import TextChatMessage from './TextChatMessage';
import MediaChatMessage from './MediaChatMessage';
import InteractiveChatMessage from './InteractiveChatMessage';
import TextStreamChatMessage from './TextStreamChatMessage';
import { format, formatDistanceToNow } from 'date-fns';
import { ChatMessage } from '../../../types/chat';
import _ from 'lodash';

interface ChatMessageBubbleProps {
    message: ChatMessage;
}

const ChatMessageBubble: React.FC<ChatMessageBubbleProps> = ({ message }) => {
    const [formattedTime, setFormattedTime] = React.useState("");

    const isUserMessage = message.role === 'user';
    const messageStyle = classNames(
        'w-fit relative px-2 py-1.5 text-sm rounded-xl',
        {
            'bg-blue-500 text-white ml-auto ml-4 rounded-br-sm': isUserMessage, 
            'dark:bg-slate-700 dark:text-white bg-gray-200 text-black mr-4 mr-auto rounded-bl-sm': !isUserMessage,
        }
    );

    const formatTimestamp = () => {
        const now = new Date();        
        const utcDate = new Date(message.timestamp);
        const messageDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);
        const diffInHours = Math.abs(now.getTime() - messageDate.getTime()) / (1000 * 60 * 60);

        if (diffInHours < 12) {
            setFormattedTime(formatDistanceToNow(messageDate) + " ago");
        } else {
            setFormattedTime(format(messageDate, "MMMM do, yyyy, h:mm a"));
        }
    };

    React.useEffect(() => {
        formatTimestamp();
        const timer = setInterval(() => {
            formatTimestamp();
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [message.timestamp]);



    return (
        <div className="w-full">
        <div className={messageStyle}>
            {renderMessageContent(message)}
        </div>
        </div>
    );
}


const renderMessageContent = (message: ChatMessage) => {
    switch (message.type) {
        case 'text':
            return <TextChatMessage message={message} />;
        case 'text-stream':
            return <TextStreamChatMessage message={message} />
        case 'image':
            return <MediaChatMessage message={message} />;
        case 'video':
            return <MediaChatMessage message={message} />;
        case 'audio':
            return <MediaChatMessage message={message} />;
        case 'interactive':
            return <InteractiveChatMessage message={message} />;
        default:
            return <TextChatMessage message={message} />;
    }
}

export default ChatMessageBubble;

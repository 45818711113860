import { FC, useEffect, useState } from 'react';
import { IoMdAdd, IoMdSend } from 'react-icons/io';
import LoadingIndicator from '../../LoadingIndicator';
import FlexibleTextarea from '../../form/FlexibleTextArea';
import _ from 'lodash';

interface ChatInputChinProps {
    sending?: boolean;
    onSend: (message: string) => void;
    onAttachFiles?: () => void; // Can be extended with more features in the future.
}

const ChatInputChin: FC<ChatInputChinProps> = ({ sending, onSend, onAttachFiles }) => {
    const [message, setMessage] = useState('');

    const handleSendClick = () => {
        if (message.trim()) {
            onSend(message.trim());
            setMessage('');
        }
    }

    const handleKeydown = (e: KeyboardEvent) => {
        const el = document.getElementById("chatInput");

        if (!el) return;
        if (document.activeElement !== el) return;
        
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSendClick();
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", handleKeydown);
        return () => {
            window.removeEventListener("keydown", handleKeydown);
        }
    }, [message]);

    const chat_input_view = (
        <div className="w-full flex items-center p-2 justify-between h-full bg-white dark:bg-slate-800">
        <FlexibleTextarea
            id="chatInput"
            value={message}
            onChange={setMessage}
            placeholder="Type your message..."
            className="dark:text-white h-full"
        />
        <button 
            onClick={handleSendClick}
            className="text-black px-2 dark:text-white"
        >
            {sending ? <LoadingIndicator/> : <IoMdSend size={24} className="dark:text-white" />}
        </button>
    </div>
    )

    return (
        <div className="w-full h-full flex flex-col justify-center items-center">
            {chat_input_view}
       </div>
    );
}

export default ChatInputChin;

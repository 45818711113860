import React, { useEffect, useRef } from 'react';
import ChatMessageBubble from '../messages/ChatMessageBubble';
import { ChatMessage } from '../../../types/chat';
import LoadingIndicator from '../../LoadingIndicator';
import EllipsesLoadingIndicator from '../../EllipsesLoadingIndicator';

interface ChatMessageFeedProps {
    messages: ChatMessage[];
    responseLoading: boolean;
}

const ChatMessageFeed: React.FC<ChatMessageFeedProps> = ({ messages, responseLoading }) => {
    const feedEndRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (feedEndRef.current) feedEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages.length, feedEndRef.current]);

    return (
        <div className="flex flex-col justify-start items-between py-4 px-4 gap-4 w-full">
            {messages.map((msg: ChatMessage, index: number) => (
                <ChatMessageBubble key={index} message={msg} />
            ))}
            { responseLoading && <EllipsesLoadingIndicator/>}
            <div ref={feedEndRef}></div>
        </div>
    );
}

export default ChatMessageFeed;


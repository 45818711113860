import { formatDistanceToNow } from 'date-fns';
import { format } from 'date-fns';

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export function generateId(): string {
    return Math.random().toString(36).substring(2, 12);
}

export function formatHumanReadableTimestamp(timestamp: string): string {
    const date = new Date(timestamp);
    const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    return format(localDate, 'PPpp');
}


export function formatDateTimeAgo(timestamp: string): string {
    const date = new Date(timestamp);
    const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    return formatDistanceToNow(localDate) + " ago";
}



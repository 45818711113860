import React, { useEffect } from 'react';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useAuth } from '../../services/AuthProvider';
import { FaChevronDown, FaChevronUp, FaMoon, FaSun } from 'react-icons/fa';
import Dropdown from '../form/Dropdown';
import useDarkMode from '../../hooks/useDarkMode';

interface LayoutProps {
    children: React.ReactNode;
}

interface NavbarProps {
  onDarkModeToggle: () => void;
  isDarkMode: boolean;
}

export const Navbar: FC<NavbarProps> = ({onDarkModeToggle, isDarkMode}) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [expanded, setExpanded] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const DropdownIcon = isOpen ? FaChevronUp : FaChevronDown;  

  const authDropdownOptions: { label: string, value: string }[] = [
    {label: 'Account', value: 'account'},
    { label: 'Logout', value: 'logout' }
  ];

  const triggerLogin = async () => {
    await auth?.signInGoogle();
  }
  
  return (
    <header className="flex flex-wrap sm:flex-nowrap w-full sm:h-[var(--navbar-height)] border-b border-b-slate-600 bg-slate-800 text-white text-sm py-4 dark:bg-slate-800">
      <nav className="w-full mx-auto px-3 sm:flex items-center justify-center sm:justify-between">
        <div className="flex flex-col sm:flex-row items-center gap-2 sm:gap-5">
          <div className="flex flex-row items-center justify-center space-x-2">
            <img src="/logo.png" alt="convokiwi" className="sm:w-6 w-8" />
            <a className="flex-none sm:text-lg text-2xl font-bold dark:text-white" href="/">ChatStuff</a> 
          </div>
          {!auth?.user && <a className="font-medium" href="/pricing">Pricing</a>}
        </div>
        <div className="flex flex-col-reverse sm:flex-row items-center justify-center gap-2 sm:gap-5 sm:ml-auto">
          <button onClick={onDarkModeToggle}>
            {isDarkMode ? <FaSun /> : <FaMoon />}
          </button>
          { !auth?.user &&<button onClick={triggerLogin}>
            Login/Signup
          </button>}
          { auth && auth.user?.email && 
            <Dropdown 
            options={authDropdownOptions}
            onToggle={(isOpen) => setIsOpen(isOpen)}
            onClick={async (index) => {
              if (index == 0) {
                navigate("/account");
                return;
              }

              if (index === 1) {
                await auth.signOut();
                setIsOpen(false)
              }
            } }
            button={<div className="flex items-center cursor-pointer" onClick={() => setIsOpen(prevState => !prevState)}>
              <p className="select-none">{auth.user.name}</p>
              <DropdownIcon className="ml-2" />
            </div>}
            isOpen={isOpen}/>
          }
        </div>
      </nav>
    </header>
  );
};

export const Layout: FC<LayoutProps> = ({ children }) => {
  const { darkMode, setDarkMode } = useDarkMode();
  
  return (
    <div className={classNames('w-screen h-screen overflow-hidden', { 'dark': darkMode, 'bg-gray-900': darkMode, 'bg-white': !darkMode })}>
          <Navbar onDarkModeToggle={() => setDarkMode(!darkMode)} isDarkMode={darkMode} />
          { children }
    </div>
  );
};

export default Layout;
import React from 'react';
import { useState } from 'react';
import { PrimaryButton } from './Buttons';
import { FaCheck } from 'react-icons/fa';
import fetchUserAPI from '../services/userApi';
import { useAuth } from '../services/AuthProvider';

type PricingCardProps = {
    product_id: string;
    title: string;
    description: string;
    price: number;
    billedFrequency: string;
    buttonText: string;
    features: Array<string>;
};

const PricingCard: React.FC<PricingCardProps> = ({
    title,
    description,
    price,
    billedFrequency,
    buttonText,
    features,
    product_id
}) => {
    const auth = useAuth();
    const [loading, setLoading] = useState(false);

    const handleSubscribe = async () => {
        if (!auth?.user) {
            await auth?.signInGoogle();
            return;
        } else {
            setLoading(true);
            try {
                const response = await fetchUserAPI('checkout', { product_id, email: auth?.user?.email });
                if (response.code == 200) {
                    window.location.href = response.data['url'];
                } else {
                    setLoading(false);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    return (
        <div className="border-2 w-[18rem] rounded-xl p-6 bg-white dark:bg-slate-800 flex flex-col items-center justify-center hover:shadow-lg transition duration-500 ease-in-out">
            <h2 className="text-2xl font-bold mb-4 dark:text-white">{title}</h2>
            <p className="mb-6 text-sm text-center dark:text-slate-300">{description}</p>
            <div className="flex items-end justify-center mb-4">
                <span className="text-4xl font-bold dark:text-white">${price}</span>
                <span className="ml-2 dark:text-slate-300">{billedFrequency}</span>
            </div>
            <PrimaryButton className="mb-6" onClick={handleSubscribe} loading={loading}>
                {buttonText}
            </PrimaryButton>
            <ul className="flex flex-col items-start justify-center mt-4">
                {features.map((feature, index) => (
                    <li key={index} className="mb-2 flex justify-center items-center space-x-4">
                        <FaCheck className="dark:text-white" /> <p className="dark:text-slate-300">{feature}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default PricingCard;

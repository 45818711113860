import { FC } from 'react';
import { AiFillFileText } from 'react-icons/ai';

interface DocumentIconProps {
  textSize?: string;
}

export const DocumentIcon: FC<DocumentIconProps> = ({ textSize = 'text-xl' }) => {
  return (
    <AiFillFileText className={`${textSize} text-blue-500`}/>
  );
};

export default DocumentIcon;
import { FC } from 'react';
import LoadingIndicator from './LoadingIndicator';

interface LoadingViewProps {
  
}

export const LoadingView: FC<LoadingViewProps> = () => {
  return (
    <div className="w-screen h-screen dark:bg-slate-900 bg-white flex items-center justify-center">
      <LoadingIndicator color={"dark:text-slate-200 text-black"} />
    </div>
  );
};

export default LoadingView;
import { useEffect, useState } from "react";
import { FirebaseError } from "@firebase/util";
import { AuthContextValue } from "../services/AuthProvider";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, signOut as logout, GoogleAuthProvider, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { browserLocalPersistence } from "firebase/auth";
import { FirebaseUser, app, auth } from "../services/firebase";
import { capitalize } from "../utilities";
import fetchUserAPI from "../services/userApi";
import { UserData } from "../types/user";

const useFirebaseAuth = (): AuthContextValue => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);

  const [user, setUser] = useState<UserData | null>(null);
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | null>(null);
  const [token, setTokenState] = useState<string | null>(null);  

  const setToken = (token: string | null) => {
    setTokenState(token);
    if (token) localStorage.setItem("token", token);
    if (!token) localStorage.removeItem("token");
  };
  
  const fetchUserData = async () => {
    const response = await fetchUserAPI("user");

    if (response.code === 200) {
      return response.data;
    }

    return null;
  }

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const handleFetch = () => {
      fetchUserData().then(res => res && setUser(prev => ({ ...prev, ...res })))
    }

    if (token) {
      handleFetch()
      intervalId = setInterval(async () => handleFetch(), 5000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [token]);

  const handleUser = async (authState: FirebaseUser | null) => {
    setLoading(true);

      if (!authState) {
          setUser(null);
          setToken(null);
          setLoading(false);
          setFirebaseUser(null);
          return;
      }
    
    setFirebaseUser(authState);

    const token = await authState.getIdToken();
    setToken(token);

    const auth = getAuth();
    auth.setPersistence(browserLocalPersistence);

    let localUserItem: UserData = {
        user_id: authState.uid,
        name: authState.displayName,
        email: authState.email,
        photo: authState.photoURL,
        created_at: authState.metadata.creationTime,
        last_login: authState.metadata.lastSignInTime,
    };

    setUser(localUserItem);
    setLoading(false);
  };

  const handleAuthError = (e: any) => {
    let message = "An unknown error occoured.";
    if (e instanceof FirebaseError) {
      message = e.code
        .split("/")[1]
        .split("-")
        .map((w) => capitalize(w))
        .join(" ");
    }
    alert(message);
    setLoading(false);
  };

  const signUpEmailPassword = async (email: string, password: string) => {
    try {
      setLoading(true);
      console.log("signing up");
      const auth = getAuth(app);
      const res = await createUserWithEmailAndPassword(auth, email, password);
      console.log("created user");
      await handleUser(res.user);
    } catch (error) {
      handleAuthError(error);
    } finally {
      setLoading(false);
    }
  };

  const signInEmailPassword = async (email: string, password: string) => {
    try {
      setLoading(true);
      const auth = getAuth(app);
      const res = await signInWithEmailAndPassword(auth, email, password);
      await handleUser(res.user);
    } catch (error) {
      console.log(error);
      handleAuthError(error);
    } finally {
      setLoading(false);
    }
  };

  const signInGoogle = async () => {
    try {
      setLoading(true);
      const auth = getAuth(app);
      const provider = new GoogleAuthProvider();
      provider.addScope("email");
      const res = await signInWithPopup(auth, provider);
      await handleUser(res.user);
    } catch (error) {
      handleAuthError(error);
    } finally {
      setLoading(false);
    }
  };

  const sendPasswordReset = async (email: string) => {
    try {
      setLoading(true);
      const auth = getAuth(app);
      await sendPasswordResetEmail(auth, email);
      alert("Password reset email sent.");
    } catch (error) {
      handleAuthError(error);
    } finally {
      setLoading(false);
    }
  };

  const signOut = async () => {
    const auth = getAuth(app);
    await logout(auth);
    localStorage.clear();
    setUser(null);
    setFirebaseUser(null);
    setTokenState(null);
    navigate("/");
  };

  useEffect(() => {
    const change = auth.onIdTokenChanged(handleUser);
    return () => change();
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      const user = auth.currentUser;
      if (user) await user.getIdToken(true);
    }, 10 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(async (user) => {
      setLoading(true)
      if (user) {        
        const token = await user.getIdToken();
        setToken(token);
        handleUser(user);
      } else {
        setUser(null);
        setToken(null);
        setFirebaseUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return {
    user,
    token,
    authState: firebaseUser,
    loading,
    signUpEmailPassword,
    signInEmailPassword,
    signInGoogle,
    sendPasswordReset,
    signOut,
  };
};

export default useFirebaseAuth;

import { useEffect, useState, useMemo, FC } from 'react';
// import AudioSourceView from './sourceViews/AudioSourceView';
// import PDFSourceView from './sourceViews/PDFSourceView';
// import TextSourceView from './sourceViews/TextSourceView';
import fetchUserAPI from '../services/userApi';
import { useParams } from 'react-router-dom';
import LoadingView from '../components/LoadingView';
import useSourceEvents from '../hooks/useSourceEvents';
import VideoSourceView from '../components/source/views/VideoSourceView';
import DocumentSourceView from '../components/source/views/DocumentSourceView/DocumentSourceView';

interface SourceContentViewProps { source: any;  };

export const SourceContentView: FC<SourceContentViewProps> = ({source}) => {    
  switch (source.file_category) {
    case 'VIDEO':
      return <VideoSourceView source={source} />;
    case "TEXT":
      return <DocumentSourceView source={source} />;
    // case 'audio':
    //   return <AudioSourceView source={source} />;
    // case 'pdf':
      // return <PDFSourceView source={source} />;
    // case 'txt':
    //   return <TextSourceView source={source} />;
    default:
      return null;
  }
};

const SourceView: FC = () => {
  const params = useParams();
  const [source, setSource] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  
  const sourceType = useMemo(() => {
    if (source && source.file_category) return source.file_category; 
    return ""; 
  }, [source]);
  
  const events = useSourceEvents(sourceType)
  
    useEffect(() => {
        if (!params.source_id) return;
        const fetchSource = async () => {
          const response = await fetchUserAPI("video", { source_id: params.source_id }, "GET");
          if (response.code === 200) setSource(response.data);
          setLoading(false);
      };
      fetchSource();
    }, [params.source_id]);
    
    
  if (loading) {
    return <LoadingView />;
  }
    
    if (!source) {
      return <div className="text-lg text-red-500">Error: No source found</div>;
    }

  return <div className="h-full w-full">
    <SourceContentView source={source}/>
  </div>
    
// return <div className="flex flex-col justify-start">
//         <div className="p-4">
//             {source.heading && <h1 className="text-2xl font-bold">{source.heading}</h1>}
//             {source.sentence_summary && <p className="mt-6">{source.sentence_summary}</p>}
//         </div>
//         <div className="w-full h-full bg-red-500 py-6 overflow-hidden">
//             <SourceContentView source={source}/>
//       </div>
//         { source.hasOwnProperty('paragraph_summary') && <div className="pt-4 px-4">
//             <h3 className="text-lg font-semibold">Paragraph Summary</h3>
//   </div>}
//         { source.hasOwnProperty('paragraph_summary') &&  <div className="p-4 pt-0">
//             <p className="text-base">{source.paragraph_summary}</p>
//       </div>}
//     </div>
}

export default SourceView;
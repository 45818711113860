import { FC } from 'react';
import ChatView from '../views/ChatView';
import SourceView from '../views/SourceView';
import SourceSidebar from '../views/SourceSidebar';
import PageContainer from '../components/containers/PageContainer';

interface ConversationPageProps {}

export const ConversationPage: FC<ConversationPageProps> = () => {
  const sidebar = <SourceSidebar />;
  const source = <SourceView />;
  const chat = <ChatView />;
  
  return (
    <PageContainer isRow>
      <div className="w-[18%] h-full border-r dark:border-slate-600">
        {sidebar}
      </div>
      <div className="w-[82%] h-full flex justify-start items-center">
        <div className="w-1/2 h-full flex flex-col justify-start items-center dark:bg-slate-800">
          {source}
        </div>
        <div className="w-1/2 h-full flex flex-col justify-center items-center overflow-y-scroll border-l dark:border-slate-600">
          {chat}
        </div>
      </div>
    </PageContainer>
  );
};

export default ConversationPage;
import { FC } from 'react';

interface ProgressIndicatorProps {
  percentage: number;
}

export const ProgressIndicator: FC<ProgressIndicatorProps> = ({percentage}) => {
  return (
        <div className="flex w-full h-6 bg-slate-200 rounded-full overflow-hidden dark:bg-slate-700">
        <div className="flex flex-col justify-center overflow-hidden bg-blue-500 text-xs text-white text-center" role="progressbar" style={{width: (percentage * 100).toString() + "%"}} aria-valuenow={percentage * 100} aria-valuemin={0} aria-valuemax={100}>{Math.round(percentage * 1000) / 10}%</div>
        </div>
  );
};

export default ProgressIndicator;
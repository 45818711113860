import { useEffect, useState } from "react";

const useDarkMode = () => {
    const [darkMode, setDarkMode] = useState(localStorage.getItem("darkMode") === "true" ? true : false);

    useEffect(() => {
      localStorage.setItem("darkMode", darkMode.toString());
    }, [darkMode])
  
  
    useEffect(() => {
      const darkModeListener = () => {
        const darkModeStorage = localStorage.getItem("darkMode");
        setDarkMode(darkModeStorage === "true" ? true : false);
      };
  
      window.addEventListener("storage", darkModeListener);
  
      return () => {
        window.removeEventListener("storage", darkModeListener);
      };
    }, []);

    return {darkMode, setDarkMode}
}

export default useDarkMode;
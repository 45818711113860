import { FC, useEffect } from 'react';
// import FileUploader from '../components/form/FileUploader';
import FileUploader from '../components/form/FileUploader';
import SourcesCardArea from '../views/SourcesCardArea';
import React from 'react';
import { useAuth } from '../services/AuthProvider';
import { ACCEPTED_FILE_TYPES, MAX_FILE_SIZE } from '../config';
import { BooleanParam, useQueryParam } from 'use-query-params';
import Modal from '../components/Modal';
import { PrimaryButton } from '../components/Buttons';
import PricingTable from '../components/PricingTable';
import UsageQuotaCard from '../components/UsageQuotaCard';
import VideoIcon from '../components/icons/VideoIcon';
import DocumentIcon from '../components/icons/DocumentIcon';
import { AiFillQuestionCircle, AiOutlineClose } from 'react-icons/ai';
import Footer from '../components/Footer';
import LoadingIndicator from '../components/LoadingIndicator';

const HomePageVideo = () => {
  const [loading, setLoading] = React.useState(true);
  const url = "https://d3v6d6klcnyjgr.cloudfront.net/data/chatstuff/home-page-video.mp4"

  return (
    <div className="rounded-lg">
      {loading && <LoadingIndicator/>}
      <video className="w-[800px] rounded-md border border-slate-400" autoPlay loop muted onLoadedData={() => setLoading(false)}>
        <source src={url} type="video/mp4"/>
      </video>
    </div>
  )
}

interface HomePageProps {}

export const HomePage: FC<HomePageProps> = () => {
  const auth = useAuth();

  const [checkout_success, setCheckoutSuccess] = useQueryParam("checkout_success", BooleanParam);
  const [upgrade, setUpgradeModal] = useQueryParam("upgrade", BooleanParam);
  const hero_section = <div className="bg-none flex justify-center items-center flex-col my-6 px-4 py-6 w-full sm:w-1/2 text-center">
    <h1 className="text-5xl font-bold dark:text-white">Chat with your stuff.</h1>
    <p className="text-lg mt-4 mb-8 dark:text-slate-300">Ask any question, get instant answers, get summaries, and more.</p>
    <HomePageVideo/>
  </div>

  const file_type_cards = (
    <div className="flex space-x-4">
      <div className="border border-slate-400 p-6 rounded-lg flex flex-col items-center space-y-2 text-center w-72 h-72 justify-center bg-white dark:bg-slate-900 dark:text-white">
        <div className="flex flex-row items-center space-x-4 mb-4">
          <VideoIcon textSize='text-4xl'/>
          <div>
            <h2 className="text-xl font-bold">Video Files</h2>
            <p className="text-sm">(mp4, mov, avi)</p>
          </div>
        </div>
        <p>Lectures, podcasts, debates, and other talking content.</p>
      </div>
      <div className="border border-slate-400 p-6 rounded-lg flex flex-col items-center space-y-2 text-center w-72 h-72 justify-center bg-white dark:bg-slate-900 dark:text-white">
        <div className="flex flex-row items-center space-x-4 mb-4">
          <DocumentIcon textSize='text-4xl'/>
          <div>
            <h2 className="text-xl font-bold">PDF Documents</h2>
            {/* <p className="text-sm">(pdf, txt, word)</p> */}
          </div>
        </div>
        <p>Text books, technical manuals, long contracts, and more.</p>
      </div>

      <div className="border border-slate-400 p-6 rounded-lg flex flex-col items-center space-y-2 text-center w-72 h-72 justify-center bg-white dark:bg-slate-900 dark:text-white">
        <div className="flex flex-row items-center space-x-4 mb-4">
          <AiFillQuestionCircle className="text-4xl"/>
          <div>
            <h2 className="text-xl font-bold">Coming Soon...</h2>
          </div>
        </div>
        <p>More media types in development.</p>
      </div>

      {/* <div className="border border-slate-400 p-6 rounded-lg flex flex-col items-center space-y-2 text-center w-72 h-72 justify-center bg-white dark:bg-slate-900 dark:text-slate-300">
        <div className="flex flex-row items-center space-x-4 mb-4">
          <YouTubeIcon/>
          <div>
            <h2 className="text-xl font-bold">YouTube</h2>            
          </div>
        </div>
        <p>YouTube lectures, tutorials, podcasts, and more.</p>
      </div>
      <div className="border border-slate-400 p-6 rounded-lg flex flex-col items-center space-y-2 text-center w-72 h-72 justify-center bg-white dark:bg-slate-900 dark:text-slate-300">
        <div className="flex flex-row items-center space-x-4 mb-4">
          <WebsiteIcon textSize='text-4xl'/>
          <div>
            <h2 className="text-xl font-bold">Websites</h2>            
          </div>
        </div>
        <p>News articles, blog posts, research papers, and more.</p>
      </div> */}
    </div>
  );

  const features_section = <div className="flex flex-col justify-center items-center space-y-8 px-4 pb-12 w-full sm:w-1/2">
    {/* <div className="w-[964px] h-[550px] rounded-lg grow bg-slate-200 dark:bg-slate-700"></div> */}
    <div className="text-lg text-center dark:text-slate-300 py-8">
    <p>ChatStuff is a tool for college students, busy professionals, and people who get stuff done 💪🏼</p>
    <p>Saving you countless hours reading, watching, and listening.</p>
    </div>
  </div>

    const how_it_works_section = (
      <div className="flex flex-col items-center text-center space-y-4 border-y border-slate-400 bg-slate-200 dark:bg-slate-800 px-24 py-32 w-full">
        <div className="text-lg dark:text-slate-300">
          <h3 className="text-2xl font-bold dark:text-white">Upload your files and start asking questions!</h3>
          <p className="dark:text-slate-300">ChatStuff accepts video files and PDFs, with more media types coming soon.</p>
          <div className="flex justify-center w-full my-8">
            {file_type_cards}
          </div>
        </div>
      </div>
    );
  
  const upload_section = <div className="flex justify-center items-center flex-col py-12 px-4 w-full sm:w-1/2 text-center">
    <h3 className="text-3xl font-bold dark:text-white mb-6">Get started by uploading something.</h3>
    <FileUploader acceptedFileTypes={ACCEPTED_FILE_TYPES} maxSize={MAX_FILE_SIZE} onUploadComplete={() => null} onUploadError={(error: string) => alert(error)} />
  </div>
  
  const user_data_section = auth?.user ? <div className="flex flex-col justify-center items-center md:w-2/3 rounded-xl p-4">
    <h3 className="text-2xl font-bold dark:text-white">Your Uploads</h3>
    <div className="flex justify-center items-center mt-4 w-full">
      <SourcesCardArea/>
  </div>
  </div> : null;
  
  const checkout_success_modal = <Modal open={checkout_success || false}>
    <div className="dark:bg-slate-800 flex flex-col items-center justify-center p-4 text-center text-black dark:text-white">
      <h2 className="text-2xl font-bold mb-4">Thank you!</h2>
      <p className="mb-6">Thanks for subscribing to ConvoKiwi, you now have expanded powers in traversing time.</p>
      <PrimaryButton onClick={() => setCheckoutSuccess(undefined)}>
        Let's go!
      </PrimaryButton>
    </div>
  </Modal>

  const upgrade_modal = <Modal open={upgrade || false} medium>
    <div className="relative dark:bg-slate-800 flex flex-col items-center justify-center p-4 text-center h-full">
      <div className="absolute top-0 right-0 cursor-pointer" onClick={() => setUpgradeModal(undefined)}><AiOutlineClose/></div>
      <h2 className="text-2xl font-bold mb-4 dark:text-white">Upgrade your plan</h2>
      <PricingTable />
    </div>
  </Modal>

  const modals = <>
    {checkout_success_modal}
    {upgrade_modal}
  </>

  const usage_card = auth?.user?.product_id && <div className="dark:bg-slate-800 fixed top-[var(--navbar-height)] right-0 px-4 py-2 border-b border-l border-slate-400 bg-white rounded-bl-lg">
    <UsageQuotaCard/>
  </div>

  return (
    <div className="relative flex justify-start items-center flex-col space-y-4 h-full overflow-y-scroll dark:bg-slate-900">
      {usage_card}
      {modals}
      {hero_section}
      {features_section}
      {how_it_works_section}
      {upload_section}
      {user_data_section}
      <Footer/>
    </div>
    
  );
};

export default HomePage;
import { FC, useEffect, useRef } from 'react';
import emitter, { EMITTER_EVENTS } from '../services/emitter';
import { VideoTimestampState } from '../atoms';
import { useRecoilState } from 'recoil';
import ReactHlsPlayer from 'react-hls-player';

interface VideoPlayerProps {
  url: string;
}

export const VideoPlayer: FC<VideoPlayerProps> = ({ url }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [, setVideoTimestamp] = useRecoilState(VideoTimestampState);

  useEffect(() => {
    if (!videoRef.current) return;

    videoRef.current.ontimeupdate = () => {
      setVideoTimestamp(videoRef.current?.currentTime || -1);
    };
  }, []);

  useEffect(() => {
    if (!videoRef.current) return;

    emitter.on(EMITTER_EVENTS.video.SEEK, (time) => {
      if (typeof time == 'number') {
        if (videoRef.current) videoRef.current.currentTime = time;

        if (videoRef.current && videoRef.current.paused) {
          videoRef.current?.play();
        }
      }
    });

    emitter.on(EMITTER_EVENTS.video.PLAY, () => {
      if (videoRef.current && videoRef.current.paused) {
        videoRef.current?.play();
      }
    });

    emitter.on(EMITTER_EVENTS.video.PAUSE, () => {
      if (videoRef.current && !videoRef.current.paused) {
        videoRef.current?.pause();
      }
    });
  }, [url]);

  return (
    <div className="w-full">
      <ReactHlsPlayer
        src={url}
        autoPlay={false}
        controls={true}
        width="100%"
        height="auto"
        playerRef={videoRef}
        disablePictureInPicture
      />
    </div>
  );
};

export default VideoPlayer;
import { FC, useEffect, useState } from 'react';
import fetchUserAPI from '../services/userApi';
import React from 'react';
import FileUploader from '../components/form/FileUploader';
import LoadingIndicator from '../components/LoadingIndicator';
import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { ACCEPTED_FILE_TYPES, MAX_FILE_SIZE } from '../config';
import DocumentIcon from '../components/icons/DocumentIcon';

interface SourceSidebarItemProps {
  selected: boolean;
  source_id: string;
  thumbnails: string[];
  heading: string;
  started_processing_at: string;
  webp_thumbnail: string;
  sentence_summary: string;
  source_filename: string
  status: string;
  file_category: string;
  file_type: string;
  object_key: string;
}

const SourceSidebarItem: FC<SourceSidebarItemProps> = ({ selected, source_id, thumbnails, heading, started_processing_at, webp_thumbnail, sentence_summary, source_filename, status, file_category, file_type, object_key }) => {
  const navigate = useNavigate();
  const formattedStatus = status.toLowerCase().replace('_', ' ');
  

  const videoThumbnail = file_category === 'VIDEO' && formattedStatus == 'complete' && thumbnails && thumbnails.length > 0 && <img src={thumbnails[0]} alt={heading} className="w-12 h-12 object-cover rounded-lg" />;
  const videoTitle = formattedStatus == 'complete' && (heading || source_filename) && <a href="#" className="focus:outline-none">
    <p className="text-sm text-slate-900 dark:text-slate-100">{heading || source_filename}</p>
    { heading && <p className="text-xs text-slate-400 dark:text-slate-500">{ source_filename }</p>}
  </a>;
  const textIcon = formattedStatus == 'complete' && file_category === 'TEXT' && <DocumentIcon textSize='text-xl' />;
  const loadingIndicator = formattedStatus !== 'complete' && <div className="w-full flex justify-start items-center px-2 space-x-4 text-sm">
    <LoadingIndicator />
    <div>
    <div>
    <p>{source_filename}</p>
    </div>
      <p className="text-xs text-slate-400 dark:text-slate-100">{formattedStatus}...</p>
    </div>
  </div>;
  const errorIndicator = status === 'ERROR' && <p className="text-red-500 dark:text-red-400 text-sm">Error loading video</p>;

  return (
    <div className={classNames("flex items-center w-full space-x-2 p-2", {"hover:bg-slate-100 dark:hover:bg-slate-800 cursor-pointer": formattedStatus == 'complete', 'bg-slate-200 dark:bg-slate-700': selected})} onClick={() => {
      if (formattedStatus == 'complete') {
        navigate(`/chat/${source_id}`);
      }}}>
      {videoThumbnail}
      {textIcon}
      {videoTitle}
      {loadingIndicator}
      {errorIndicator}
    </div>
  );
};


interface SourceSidebarProps {
  
}

export const SourceSidebar: FC<SourceSidebarProps> = () => {
  const params = useParams();
  const source_id = params['source_id'] || null;
  const [sources, setSources] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchSources = async () => {
      const response = await fetchUserAPI("videos")
      if (response.code == 200) {
        const sortedSources = response.data.sort((a: any, b: any) => {
          const dateA = a.started_processing_at ? new Date(a.started_processing_at).getTime() : 0;
          const dateB = b.started_processing_at ? new Date(b.started_processing_at).getTime() : 0;
          return dateA - dateB;
      });
        setSources(sortedSources);
      }
    };
    setLoading(true)
    fetchSources().then(() => setLoading(false))
    const interval = setInterval(fetchSources, 4000);
    return () => clearInterval(interval);
  }, []);

  return (
      <div className='w-full h-full flex flex-col justify-center items-center dark:bg-slate-900'>
          <div className="h-[2rem] w-full flex justify-start px-2 items-center dark:bg-slate-800 border-b dark:border-slate-600">
              <p className="text-sm text-slate-900 dark:text-slate-300">Your Uploads</p>
          </div>
        <div className="h-[calc(100%-10rem)] flex flex-col justify-start items-start overflow-y-scroll w-full">
            {loading ? (
              <div className="w-full flex justify-center items-center p-4"><LoadingIndicator /></div>
            ) : (
                sources.map((source, index) => (
                  <SourceSidebarItem key={index} {...source} selected={source_id == source.source_id} />
                ))
            )}
          </div>
          <div className="h-[8rem] w-full flex justify-center items-center p-4 dark:bg-slate-900">
                <FileUploader 
                    small
                    acceptedFileTypes={ACCEPTED_FILE_TYPES}
                    maxSize={MAX_FILE_SIZE}
                    onUploadComplete={() => console.log("Upload complete")}
                    onUploadError={(errorMessage: string) => console.log(errorMessage)}
            />
          </div>
    </div>
  );
};

export default SourceSidebar;
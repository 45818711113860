import { FC } from 'react';

interface EllipsesLoadingIndicatorProps {
  
}

export const EllipsesLoadingIndicator: FC<EllipsesLoadingIndicatorProps> = () => {
  return (
    <div className="flex space-x-1 my-2">
      <div className="bg-slate-300 h-1 w-1 rounded-full animate-strong-bounce" style={{ animationDelay: '0ms' }}></div>
      <div className="bg-slate-300 h-1 w-1 rounded-full animate-strong-bounce" style={{ animationDelay: '100ms' }}></div>
      <div className="bg-slate-300 h-1 w-1 rounded-full animate-strong-bounce" style={{ animationDelay: '300ms' }}></div>
    </div>
  );
};

export default EllipsesLoadingIndicator;
import { FC } from 'react';
import { AiFillVideoCamera } from 'react-icons/ai';

interface VideoIconProps {
  textSize?: string;
}

export const VideoIcon: FC<VideoIconProps> = ({ textSize = 'text-xl' }) => {
  return (
    <AiFillVideoCamera className={`${textSize} dark:text-slate-300`}/>
  );
};

export default VideoIcon;
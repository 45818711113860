import React, { useEffect } from 'react';
import { FC } from 'react';
import useOutsideClick from '../../hooks/useOutsideClick';

interface DropdownProps {
  options: { label: string, value: string }[];
  onClick: (index: number) => void;
  button: JSX.Element;
  isOpen: boolean;
  onToggle?: (isOpen: boolean) => void;
  position?: 'left' | 'right';
}

export const Dropdown: FC<DropdownProps> = ({ options, onClick, button, isOpen, onToggle, position = 'right' }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const outside = useOutsideClick(ref);

  useEffect(() => {
    if (outside.outside && isOpen) {
      if (onToggle) onToggle(false);
    }
  }, [outside.outside, isOpen]);

  const handleClick = (index: number) => {
    onClick(index);
    if (onToggle) onToggle(false);
  };

  return (
    <div className={`hs-dropdown relative inline-flex flex-col ${position === 'left' ? 'items-start' : 'items-end'}`} ref={ref}>
      { button }
      <div className={`hs-dropdown-menu transition-[margin] duration-[0.1ms] ${isOpen ? 'block' : 'hidden'} w-72 z-10 mt-2 min-w-[15rem] bg-white shadow-md rounded-lg p-2 dark:bg-slate-800 dark:border dark:border-slate-700 dark:divide-slate-700 absolute top-full`} aria-labelledby="hs-dropdown-default">
        {options.map((option, index) => (
          <a key={index} className="cursor-pointer flex items-center gap-x-3.5 py-1 px-2 rounded-md text-sm text-slate-800 hover:bg-slate-100 focus:ring-2 focus:ring-blue-500 dark:text-slate-400 dark:hover:bg-slate-700 dark:hover:text-slate-300" onClick={() => handleClick(index)}>
            {option.label}
          </a>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
import React from 'react';
import LoadingIndicator from './LoadingIndicator';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    loading?: boolean;
    icon?: JSX.Element;
}

const PrimaryButton: React.FC<ButtonProps> = ({ loading, icon, ...props }) => (
    <button {...props} className={`bg-blue-500 hover:bg-blue-400 text-white flex justify-center items-center font-bold py-2 px-4 rounded ${props.disabled ? 'opacity-50 cursor-not-allowed' : ''}`}>
        {loading ? <LoadingIndicator color='text-white' /> : icon ? icon : props.children}
    </button>
);

const SecondaryButton: React.FC<ButtonProps> = ({ loading, icon, ...props }) => (
    <button {...props} className={`bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded ${props.disabled ? 'opacity-50 cursor-not-allowed' : ''}`}>
        {loading ? <LoadingIndicator color='text-white'/> : icon ? icon : props.children}
    </button>
);

const TextButton: React.FC<ButtonProps> = ({ loading, icon, ...props }) => (
    <button {...props} className={`text-blue-500 hover:text-blue-500 font-bold py-2 px-4 rounded ${props.disabled ? 'opacity-50 cursor-not-allowed' : ''}`}>
        {loading ? <LoadingIndicator color='text-convokiwi' /> : icon ? icon : props.children}
    </button>
);

const IconButton: React.FC<ButtonProps> = ({ loading, icon, ...props }) => (
    <button {...props} className={`bg-transparent hover:bg-blue-500 text-blue-500 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded ${props.disabled ? 'opacity-50 cursor-not-allowed' : ''}`}>
        {loading ? <LoadingIndicator color='text-white'/> : icon ? icon : props.children}
    </button>
);

export { PrimaryButton, SecondaryButton, TextButton, IconButton };


import { initializeApp } from "firebase/app";
import { getAuth, User, browserSessionPersistence } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD1lKrsEBi0jbr7ljn_4NpT5WrE1gYeXRA",
  authDomain: "video-convos.firebaseapp.com",
  projectId: "video-convos",
  storageBucket: "video-convos.appspot.com",
  messagingSenderId: "1009436534114",
  appId: "1:1009436534114:web:a40cf8b28c7c9a0e1b383b",
  measurementId: "G-2SD65PE0B8"
};

export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export type FirebaseUser = User;
auth.setPersistence(browserSessionPersistence);

const analytics = getAnalytics(app);
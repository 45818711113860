import { FC, useEffect, useState } from 'react';
import VideoPlayer from '../../VideoPlayer';
import fetchAPI from '../../../services/api';
import TranscriptData from '../../TranscriptData';
import { useRecoilState } from 'recoil';
import { VideoTimestampState } from '../../../atoms';

interface VideoSourceViewProps {
    source: any;
}

export const VideoSourceView: FC<VideoSourceViewProps> = ({ source }) => {
  const [transcriptData, setTranscriptData] = useState<any>(null);
  const [, setVideoTimestampState] = useRecoilState(VideoTimestampState);
  
  useEffect(() => { 
    setVideoTimestampState(0);
    if (source.transcript_data_url) {
      fetch(source.transcript_data_url)
        .then(res => res.json())
        .then(data => setTranscriptData(data))
    }
  }, [source]);

  return (
    <div className="flex flex-col justify-center items-center px-2 pt-2 h-full">
      {/* <h3 className="my-2 text-lg font-bold dark:text-slate-300">{source.source_filename}</h3> */}
      {source.hls_playlist_file && <VideoPlayer url={source.hls_playlist_file} />}
      <div className="w-full h-full overflow-y-scroll py-6">
        {transcriptData && <TranscriptData data={transcriptData} />}
      </div>
    </div>
  );
};

export default VideoSourceView;
import { FC } from 'react';
import { IoNewspaper } from 'react-icons/io5';

interface WebsiteIconProps {
  textSize?: string;
}

export const WebsiteIcon: FC<WebsiteIconProps> = ({ textSize = 'text-xl' }) => {
  return (
    <IoNewspaper className={`${textSize} dark:text-slate-300`}/>
  );
};

export default WebsiteIcon;
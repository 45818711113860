// src/components/chat/ChatView.tsx
import React, { ReactNode, useEffect } from 'react';
import ChatMessageFeed from '../components/chat/views/ChatMessageFeed';
import ChatInputChin from '../components/chat/views/ChatInputChin';
import LoadingIndicator from '../components/LoadingIndicator';
import { useChatMessages } from '../hooks/useChatMessages';
import ChatHeading from '../components/chat/views/ChatHeading';

interface ChatViewProps { }

const ChatView: React.FC<ChatViewProps> = ({ }) => {
    const { conversation, conversations, loading, responseLoading, selectConversation, sendMessage, reset} = useChatMessages();

    const handleAttachFiles = () => {
        // Implement file attachment logic here
    };

    const handleSendMessage = async (message: string) => {
        await sendMessage(message)
    }

    let content: ReactNode | null = <div className="w-full h-full flex justify-center items-center"><LoadingIndicator/></div>

    if (!loading) {
        content = <ChatMessageFeed messages={conversation?.messages || []} responseLoading={responseLoading} />
    }

    let heading = <ChatHeading conversations={conversations || []} onChatSelect={selectConversation} onResetClick={reset} />
    let chin = <ChatInputChin onSend={handleSendMessage} sending={loading} onAttachFiles={handleAttachFiles} />

    return (
    <div className="w-full md:h-[calc(100vh-var(--navbar-height))] h-[calc(2/3*100vh)] dark:bg-slate-900">
        <div className="h-[2rem] bg-white dark:bg-slate-700 border-b dark:border-slate-600">
            {heading}
        </div>
        <div className="overflow-y-scroll h-[calc(100%-5rem)]">
            {content}
        </div>
        <div className="h-[3rem] bg-white dark:bg-slate-900 border-t dark:border-slate-600">
            {chin}
        </div>
    </div>
    );
}

export default ChatView;
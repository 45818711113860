import React, { useEffect, useState } from 'react';
import { ChatMessage } from '../../../types/chat';

interface TextStreamChatMessageProps {
    message: ChatMessage;
}

const TextStreamChatMessage: React.FC<TextStreamChatMessageProps> = ({ message }) => {
    throw new Error("This component is not implemented yet.");
    // return null;
    // const agent_comm_url = localStorage.getItem("AGENT_COMMUNICATION_URL") || "http://localhost:8002"
    
    // const sse = useSSE({ url: agent_comm_url, id: message.stream_id });
    // const [text, setText] = useState<string>(message.stream_status == 'inactive' ? message.content : '');

    // useEffect(() => {
    //     if (sse.length > 0) {
    //         setText(sse.map((e: any) => e.data).join('') || "");
    //     }
    // }, [sse]);

    // return <p>{text}</p>;
}

export default TextStreamChatMessage;

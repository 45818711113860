import { FC, useEffect } from 'react';
import VideoIcon from './icons/VideoIcon';
import DocumentIcon from './icons/DocumentIcon';
import YouTubeIcon from './icons/YouTubeIcon';
import WebsiteIcon from './icons/WebsiteIcon';

interface SourceIconProps {
  source_type: string;
}

export const SourceIcon: FC<SourceIconProps> = ({ source_type }) => {
    useEffect(() => { console.log("sourceicon", source_type) }, ["sourceicon", source_type]);
  switch (source_type) {
    case 'VIDEO':
      return <VideoIcon />;
    case 'TEXT':
      return <DocumentIcon />;
    case 'YOUTUBE':
      return <YouTubeIcon />;
    case 'WEBSITE':
      return <WebsiteIcon />;
    default:
      return <div />;
  }
};

export default SourceIcon;
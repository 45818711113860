import { FC, useEffect } from 'react';
import { useAuth } from '../services/AuthProvider';
import { PrimaryButton, SecondaryButton } from '../components/Buttons';
import PricingTable from '../components/PricingTable';
import { ACCOUNT_MANAGEMENT_PORTAL } from '../config';
import UsageQuotaCard from '../components/UsageQuotaCard';

interface AccountPageProps {}

export const AccountPage: FC<AccountPageProps> = () => {
    const auth = useAuth();

    if (!auth?.user) {
        return (
            <div className="flex items-center justify-center h-screen">
                <p>Not logged in</p>
            </div>
        )
    }
  
  const usage_card = auth?.user?.product_id && <div className="dark:bg-slate-800 fixed top-[var(--navbar-height)] right-0 px-4 py-2 border-b border-l border-slate-400 bg-white rounded-bl-lg">
    <UsageQuotaCard/>
  </div>
    
  return (
    <div className="mt-12">
      {usage_card}
        <h2 className="w-full flex justify-center items-center my-4 mb-12 text-2xl font-bold dark:text-white">Account Information</h2>
        <div className="flex flex-col items-center justify-center">
            <img className="w-24 h-24 rounded-full mb-4" src={auth?.user?.photo || ""} alt="User profile" />
            <h3 className="text-xl font-bold mb-2 dark:text-white">{auth?.user?.name || "User"}</h3>
            <p className="mb-2 dark:text-gray-300">{auth?.user?.email || "No email provided"}</p>
            <p className="mb-2 text-lg bg-slate-200 px-2 text-black py-1 rounded-lg dark:bg-gray-700 dark:text-white">{auth?.user?.product_name || "Free Plan"}</p>
        <div className="text-center mt-6">
            {auth?.user?.upload_hours_limit && <p className="mb-2 dark:text-gray-300">Upload Limit: {auth?.user?.upload_hours_limit}</p>}
            {auth?.user?.uploaded_hours && <p className="mb-2 dark:text-gray-300">Uploaded Hours: {auth?.user?.uploaded_hours}</p>}
            {auth?.user?.renewal_date && <p className="mb-2 dark:text-gray-300">Next Billing Date: {auth?.user?.renewal_date}</p>}
            </div>
            <br />
            {auth?.user?.product_id && <PrimaryButton onClick={() => window.location.href = ACCOUNT_MANAGEMENT_PORTAL}>Manage Plan</PrimaryButton>}
        </div>
        {!auth?.user?.product_id && (
            <div className="mt-6">
          <h2 className="w-full flex justify-center items-center my-4 mb-5 text-2xl font-bold dark:text-white">Upgrade Your Plan</h2>
          <PricingTable/>
          </div>
        )}
    </div>
  );
};

export default AccountPage;
import mitt, { EventType } from "mitt";

const VIDEO_EVENTS = {
    PAUSE: 'video.pause',
    PLAY: 'video.play',
    SEEK: 'video.seek',
}

const TEXT_EVENTS = {
    JUMP_TO_PAGE: 'text.jump_to_page',
}

const CITATION_EVENTS = {
    citation_click: 'citation.click',
}

export const EMITTER_EVENTS = {
    video: VIDEO_EVENTS,
    text: TEXT_EVENTS,
    citation: CITATION_EVENTS,
}

export type EmitterEvent = {
    type: string;
    data: any;
}

const emitter = mitt();
export default emitter;
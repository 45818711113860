import { useEffect } from 'react';
import emitter, { EMITTER_EVENTS } from '../services/emitter';
import fetchUserAPI from '../services/userApi';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { citationLoadingState } from '../atoms';

const useSourceEvents = (source_type: string) => {
    const params = useParams();
    const source_id = params.source_id || null;

    const [, setCitationLoading] = useRecoilState(citationLoadingState);

    const handleSourceEvent = async (data: any) => {
        if (!data) return;

        if (source_type == 'VIDEO') {
            if (data?.data?.start) emitter.emit(EMITTER_EVENTS.video.SEEK, data.data.start);
        }

        if (source_type == 'TEXT') {
            if (data?.page) emitter.emit(EMITTER_EVENTS.text.JUMP_TO_PAGE, {page: data.page});
        }
    }

    useEffect(() => {
        const handleCitationClick = async (event: any) => {
            setCitationLoading({ id: event.citation_id || "", loading: true });
            const response = await fetchUserAPI("text-source", { source_id, chunk_id: event.citation }, "GET");
            if (response.code === 200) handleSourceEvent(response.data);
            setCitationLoading({ id: event.citation_id || "", loading: false })
        };

        emitter.on(EMITTER_EVENTS.citation.citation_click, handleCitationClick);

        return () => {
            emitter.off(EMITTER_EVENTS.citation.citation_click, handleCitationClick);
        };
    }, [source_type, source_id]);
}

export default useSourceEvents;
import React from 'react';
import { FC, ReactNode } from 'react';

interface PageContainerProps {
  children: ReactNode;
  isRow?: boolean;
}

export const PageContainer: FC<PageContainerProps> = ({ children, isRow = false }) => {
  return (
    <div className={`absolute w-screen h-[calc(100%-var(--navbar-height))] ${isRow ? 'flex flex-row' : ''}`}>
      {children}
    </div>
  );
};
export default PageContainer;
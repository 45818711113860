import React from 'react';
import { ChatMessage } from '../../../types/chat';

interface MediaChatMessageProps {
    message: ChatMessage;
}

const MediaChatMessage: React.FC<MediaChatMessageProps> = ({ message }) => {
    const verifyURL = (url: string): boolean => {
        // TODO: implement robust URL checking
        // return url.startsWith('https://');
        return true;
    };

    if (!verifyURL(message.content)) {
        return <div className="text-red-500">Unsafe media source.</div>;
    }

    switch (message.type) {
        case 'image':
            return <img src={message.content} alt="media" className="max-w-xs rounded-lg" />;
        case 'video':
            return <video src={message.content} controls className="max-w-xs rounded-lg" />;
        case 'audio':
            return <audio src={message.content} controls className="w-full" />;
        default:
            return null;
    }
}

export default MediaChatMessage;
import { FC } from 'react';
import PDFSourceView from './PDFSourceView';

interface DocumentSourceViewProps {
  source: any;
}

export const DocumentSourceView: FC<DocumentSourceViewProps> = ({ source }) => {
    if (!source.document_url) return null;

  switch (source.file_type) {
    case 'pdf':
      return <PDFSourceView pdfUrl={source.document_url} />;
    default:
      return <div></div>;
  }
};

export default DocumentSourceView;
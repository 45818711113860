import { FC, useEffect, useRef, useState } from 'react';
import { Document, Outline, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

import emitter, { EMITTER_EVENTS } from '../../../../services/emitter';
import React from 'react';
import LoadingIndicator from '../../../LoadingIndicator';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { DocumentCallback, OnDocumentLoadSuccess } from 'react-pdf/dist/cjs/shared/types';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
pdfjs.GlobalWorkerOptions.workerSrc = url

interface PDFSourceViewProps {
  pdfUrl: string;
}

export const PDFSourceView: FC<PDFSourceViewProps> = ({ pdfUrl }) => {
    const params = useParams();
    const source_id = params.source_id || null;
    const [numPages, setNumPages] = useState<number | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [scale, setScale] = useState(1.0);
    const pageRefs = useRef<(React.RefObject<HTMLDivElement>)[]>([]);
    const [inputPage, setInputPage] = useState(1);
    const [showOutline, setShowOutline] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const [justLoaded, setJustLoaded] = useState(true);
    const [center, setCenter] = useState(true);

  useEffect(() => {
    const jumpToPage = (event: any) => {      
      const page = event.page;
      setCurrentPage(page);
      setInputPage(page);
      if (pageRefs.current[page - 1]?.current) {
        pageRefs.current[page - 1].current?.scrollIntoView({ behavior: 'smooth' });
      }
    };

    emitter.on(EMITTER_EVENTS.text.JUMP_TO_PAGE, jumpToPage);
    return () => {
      emitter.off(EMITTER_EVENTS.text.JUMP_TO_PAGE, jumpToPage);
    };
  }, []);


  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const pageNumber = Number(entry.target.getAttribute('data-page-number'));
            if (justLoaded) {
              if (pageNumber === 1) {
                setCurrentPage(pageNumber);
                setInputPage(pageNumber);
                setJustLoaded(false);
              }
            } else {
              setCurrentPage(pageNumber);
              setInputPage(pageNumber);
            }
          }
        });
      },
      { threshold: 0.5 },
    );
  
    pageRefs.current.forEach(ref => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });
  
    return () => {
      pageRefs.current.forEach(ref => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [numPages, justLoaded]);

    function onDocumentLoadSuccess(docLoad: DocumentCallback) {
        // const outline = await docLoad.getOutline()
      setNumPages(docLoad.numPages);
      pageRefs.current = Array(docLoad.numPages).fill(null).map(() => React.createRef());
    }

  const handlePageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputPage(parseInt(event.target.value)); // Directly set the input value
  };

  const handlePageSubmit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const pageNumber = Number(inputPage);
      if (pageNumber > 0 && pageNumber <= numPages!) {
        setCurrentPage(pageNumber);
        emitter.emit(EMITTER_EVENTS.text.JUMP_TO_PAGE, { page: pageNumber });
      }
    }
  };

  const handleBlur = () => {
    const pageNumber = Number(inputPage);
    if (pageNumber > 0 && pageNumber <= numPages!) {
      setCurrentPage(pageNumber);
      emitter.emit(EMITTER_EVENTS.text.JUMP_TO_PAGE, { page: pageNumber });
    }
  };
    
    const onOutlineItemClick = (event: any) => {
        if (!event.pageNumber) return;
        emitter.emit(EMITTER_EVENTS.text.JUMP_TO_PAGE, { page: event.pageNumber });
        setShowOutline(false);
    }

  const pdf_loading = <div className="flex justify-center items-center w-full h-full p-12"><LoadingIndicator/></div>
  const pdf_error = <div className="flex justify-center items-center w-full h-full p-12">Error loading PDF</div>;
  const pdf_no_data = <div className="flex justify-center items-center w-full h-full p-12">No data available</div>;


  return (
      <div ref={containerRef} className="w-full h-full flex flex-col items-center dark:bg-slate-600 bg-slate-300 overflow-hidden">
      <div className="sticky top-0 z-10 w-full flex justify-between items-center h-[2rem] p-2 space-x-2 dark:bg-slate-800 bg-white border-b dark:border-slate-600">
        <div className="flex items-center space-x-2 dark:text-slate-200 text-sm">
            <span>
                <input type="number" value={inputPage.toString()} onChange={handlePageChange} onBlur={handleBlur} onKeyDown={handlePageSubmit} className="w-7 text-center outline-none dark:bg-slate-600 dark:border-slate-500 border rounded-lg bg-none mr-1 appearance-none" />
                / {numPages}
            </span>
        </div>
        <div className="flex items-center space-x-2 dark:text-slate-200 text-sm">
          <button onClick={() => setScale(scale - 0.1)}><AiOutlineMinus /></button>
          <span>{Math.round(scale * 100)}%</span>
          <button onClick={() => setScale(scale + 0.1)}><AiOutlinePlus /></button>
        </div>
        {/* <button onClick={() => setShowOutline(!showOutline)}>Show Outline</button> */}
      </div>
      <div className={classNames({"overflow-auto h-[calc(100%-2rem)] w-full flex flex-col justify-start items-center": true})} style={{ scrollbarWidth: 'thin' }}>
        <Document 
            inputRef={(ref: HTMLDivElement | null) => {
              if (ref && containerRef.current) {
                  const isOverflowing = ref.offsetWidth > containerRef.current.offsetWidth;
                  setCenter(!isOverflowing);
              }
            }}
          file={pdfUrl} 
          onLoadSuccess={onDocumentLoadSuccess} 
          loading={pdf_loading}
          error={pdf_error}
          noData={pdf_no_data}>
            {/* {showOutline && 
              <div className="absolute top-0 z-20 bg-white p-4 rounded-lg shadow-lg">
                <Outline 
                    onItemClick={onOutlineItemClick} 
                    onLoadSuccess={(ol) => { console.log('onLoadSuccess', ol); }}
                    onLoadError={(ol) => { console.log('onLoadError', ol); }}
                    className="invisible"
                />
              </div>
            } */}

        {Array.from(new Array(numPages), (el, index) => (
          <div ref={pageRefs.current[index]} key={`page_${index + 1}`} className="" data-page-number={index + 1}>
            <Page pageNumber={index + 1} scale={scale} className="my-4 shadow" loading={<div></div>} />
           </div>
          ))}
        </Document>
      </div>
    </div>
  );
};

export default PDFSourceView;
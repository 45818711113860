import { FC, useMemo } from 'react';
import { FaList, FaRedo } from 'react-icons/fa';
import Dropdown from '../../form/Dropdown';
import { ChatConversation } from '../../../types/chat';
import { formatHumanReadableTimestamp } from '../../../utilities';
import React from 'react';

interface ChatHeadingProps {
    conversations: Partial<ChatConversation>[];
    onChatSelect: (conversation_id: string) => void;
  onResetClick: () => void;
}

export const ChatHeading: FC<ChatHeadingProps> = ({ conversations, onChatSelect, onResetClick }) => {
  const [isChatHistoryOpen, setIsChatHistoryOpen] = React.useState(false);

  const handleChatHistoryToggle = () => {
    setIsChatHistoryOpen(!isChatHistoryOpen);
  };

    const conversations_list: {value: string, label: string}[] = useMemo(() => {
        if (!conversations) return [];
        return conversations.map((conversation: Partial<ChatConversation>, index: number) => {
            const msg = `Chat ${index + 1}`;
            const datetime = conversation?.conversation_created_at ? formatHumanReadableTimestamp(conversation?.conversation_created_at) : null;
            return { value: conversation?.conversation_id || msg, label: datetime || msg}
        });
    }, [conversations])

  return (
    <div className="w-full h-full px-4 flex justify-between items-center text-xs gb dark:bg-slate-800">
          <Dropdown button={<button onClick={handleChatHistoryToggle} className="flex items-center dark:text-white">
        <FaList />
        <span className="ml-2">Chats</span>
      </button>} position='left' options={conversations_list} onClick={(index) => onChatSelect(conversations_list[index].value)} isOpen={isChatHistoryOpen}/>
      <button onClick={onResetClick} className="flex items-center dark:text-white">
        <FaRedo />
        <span className="ml-2">Reset</span>
      </button>
    </div>
  );
};

export default ChatHeading;
import React from 'react';
import { ChatMessage } from '../../../types/chat';
import InlineCitation from './InlineCitation';
import emitter, { EMITTER_EVENTS } from '../../../services/emitter';
import { generateId } from '../../../utilities';

interface TextChatMessageProps {
    message: ChatMessage;
    className?: string;
}

const TextChatMessage: React.FC<TextChatMessageProps> = ({ message, className }) => {
    const formatText = (text: string) => {
        const citationRegex = /\[(.*?)\]/g;
        let match: any;
        let lastIndex = 0;
        const formattedText: any[] = [];

        while ((match = citationRegex.exec(text)) !== null) {
            if (match.index > lastIndex) {
                formattedText.push(text.substring(lastIndex, match.index));
            }
            
            const citationKey = match[0] || null;

            if (citationKey) {
                const citation_id = generateId();

                formattedText.push(
                    <InlineCitation 
                        key={citation_id}
                        id={citation_id}
                        citation={match[1]} 
                        onClick={() => emitter.emit(EMITTER_EVENTS.citation.citation_click,
                            {
                                citation_id, citation_key: citationKey || null,
                                citation: message.citations?.[citationKey] || null, message
                            })}
                    />
                );
                lastIndex = citationRegex.lastIndex;
            }
        }

        if (lastIndex < text.length) {
            formattedText.push(text.substring(lastIndex));
        }

        return formattedText;
    }

    const parsedMessage = message.content?.split('\n').map((text: string, index: number) => (
        <React.Fragment key={index}>
            {formatText(text)}
            <br />
        </React.Fragment>
    ));

    return <p className={className}>{parsedMessage}</p>;
}

export default TextChatMessage;
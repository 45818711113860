import { FC } from 'react';
import { FaFacebookF, FaLinkedinIn, FaTwitter, FaYoutube } from 'react-icons/fa';

interface FooterProps {
  
}

export const Footer: FC<FooterProps> = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center my-[50rem] border-t border-slate-400 pt-12 pb-24 text-xs text-slate-400">
    {/* <div className="flex space-x-2 text-lg mb-4">
      <button className="p-2 rounded-full text-slate-400 hover:text-slate-500" onClick={() => window.open('https://www.facebook.com', '_blank')}>
        <FaFacebookF />
      </button>
      <button className="p-2 rounded-full text-slate-400 hover:text-slate-500" onClick={() => window.open('https://www.twitter.com', '_blank')}>
        <FaTwitter />
      </button>
      <button className="p-2 rounded-full text-slate-400 hover:text-slate-500" onClick={() => window.open('https://www.youtube.com', '_blank')}>
        <FaYoutube />
      </button>
      <button className="p-2 rounded-full text-slate-400 hover:text-slate-500" onClick={() => window.open('https://www.linkedin.com', '_blank')}>
        <FaLinkedinIn />
      </button>
    </div> */}
    <p>© Axis Nimble 2023</p>
    <a href="mailto:aaren@chatstuff.io">Contact</a>
  </div>
  );
};

export default Footer;
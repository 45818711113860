import { FC, useEffect, useMemo, useState } from "react";
import { formatDateTimeAgo, formatHumanReadableTimestamp } from "../utilities";
import LoadingIndicator from "./LoadingIndicator";
import classNames from "classnames";
import { AiFillFileText } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import SourceIcon from "./SourceIcon";

export interface SourceData {
    source_id: string;
    thumbnails: string[];
    heading: string;
    started_processing_at: string;
    completed_processing_at: string;
    webp_thumbnail: string;
    sentence_summary: string;
    source_filename: string;
    status: string;
    file_category: string;
    file_type: string;
    bucket_name: string;
    object_key: string;
}

const SourceCard: FC<SourceData> = (props) => {
    const { source_id, thumbnails, heading, started_processing_at, completed_processing_at, webp_thumbnail, sentence_summary, source_filename, status, file_category, file_type, bucket_name, object_key } = props;
    const [currentThumbnail, setCurrentThumbnail] = useState<string>(thumbnails && thumbnails.length > 0 ? thumbnails[0] : webp_thumbnail || "");
    const navigate = useNavigate();

    const rotateThumbnails = () => {
        if (!currentThumbnail) return;
        const currentIndex = thumbnails.indexOf(currentThumbnail);
        const nextIndex = (currentIndex + 1) % thumbnails.length;
        setCurrentThumbnail(thumbnails[nextIndex]);
    };

    useEffect(() => {
        const interval = setInterval(rotateThumbnails, 1000);
        return () => clearInterval(interval);
    }, [currentThumbnail, thumbnails]);

    const formattedDate = useMemo(() => {
        if (!completed_processing_at) return "";
        return formatHumanReadableTimestamp(completed_processing_at);
    }, [started_processing_at]);

    const formattedStatus = status.replace('_', ' ').toLowerCase();
    
    useEffect(() => { console.log(formattedStatus) }, [formattedStatus]);

    const cardClassNames = classNames("relative p-4 border bg-white border-slate-400 dark:border-slate-700 rounded-xl space-y-2 w-64 h-96 m-4 dark:bg-slate-800 overflow-ellipsis overflow-hidden",
        { 'hover:scale-105 transition-all dark:hover:bg-slate-700 cursor-pointer': formattedStatus === 'complete' });

    const loadingView = (
        <div className="overflow-visible h-full w-full bg-opacity-50 flex flex-col items-center justify-between space-y-2">
            { formattedStatus != 'complete' && <h2 className="font-bold text-lg text-slate-800 dark:text-slate-200">{heading || source_filename}</h2>}
            <LoadingIndicator />
            <div className="text-center">
                <p className="text-black dark:text-slate-400 max-w-full text-sm overflow-hidden">{formattedStatus}...</p>
                {started_processing_at && <p className="text-xs text-slate-400">started {formatDateTimeAgo(started_processing_at)}</p>}
            </div>
        </div>
    );
    const errorView = (
        <div className="absolute inset-0 bg-red-500 flex items-center justify-center min-w-min">
            <p className="text-white max-w-full overflow-hidden">Error loading video</p>
        </div>
    );
    const completeView = (
        <div className="flex flex-col h-full justify-between">
            <div className="flex items-start space-x-2 max-h-1/3 overflow-ellipsis">
                <div className="w-6 h-6 pt-1">
                    <SourceIcon source_type={file_category} />
                </div>
                <h2 className="font-bold text-lg text-slate-800 dark:text-slate-200 inline-block">{heading || source_filename}</h2>
            </div>
            <div className="h-full flex flex-col justify-center items-center">
                {currentThumbnail && <img className="w-full h-auto object-cover rounded" src={currentThumbnail} alt={heading} />}
                <p className="text-sm line-clamp-3 text-slate-800 dark:text-slate-200">{sentence_summary}</p>
            </div>
            <div className="text-xs text-slate-400 dark:text-slate-400">{formattedDate}</div>
        </div>
    );
    
    return (
        <div className={cardClassNames}
            onClick={() => { if (formattedStatus == 'complete') navigate(`/chat/${source_id}`) }}>
            {formattedStatus !== 'complete' && loadingView}
            {formattedStatus === 'error' && errorView}
            {formattedStatus === 'complete' && completeView}
        </div>
    );
};

export default SourceCard;

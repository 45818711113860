import React, { useState, useMemo, useRef, useEffect } from 'react';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import fetchUserAPI from '../../services/userApi';
import ProgressIndicator from '../ProgressIndicator';
import {AiFillCheckCircle, AiOutlineUpload} from 'react-icons/ai';
import classNames from 'classnames';
import LoadingIndicator from '../LoadingIndicator';
import { useAuth } from '../../services/AuthProvider';
import { generateId } from '../../utilities';
import { useRecoilState, useRecoilValue } from 'recoil';
import {  VideosState } from '../../atoms';
import { MAX_FREE_VIDEO_DURATION_SEC } from '../../config';
import { BooleanParam, useQueryParam } from 'use-query-params';

interface FileUploaderProps {
    acceptedFileTypes: string;
    maxSize: number;
    onUploadComplete: () => void;
    onUploadError: (errorMessage: string) => void;
    small?: boolean;
}


const FileUploader: React.FC<FileUploaderProps> = ({ acceptedFileTypes, maxSize, onUploadComplete, onUploadError, small }) => {
    const auth = useAuth();
    const videos = useRecoilValue(VideosState);

    const [upgrade, setUpgradeModal] = useQueryParam("upgrade", BooleanParam);
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
    const [uploadPercentage, setUploadPercentage] = useState<number | null>(null);
    const cancelTokenSource = useRef<CancelTokenSource | null>(null);
    const [uploadURL, setUploadURL] = useState<any>(null);
    const [isSigning, setIsSigning] = useState<boolean>(false);
    const [fileName, setFileName] = useState<string | null>(null);
    const [isDragOver, setIsDragOver] = useState(false);


    const handleFileDrop = async (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (e.dataTransfer.items) {
            const files = e.dataTransfer.files;
            if (files && files.length > 0) {
                await handleFileChange(files);
                await handleUpload();
            }
        }
        setIsDragOver(false);
    };

    const handleValidateUser = async (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        if (!validateUser()) {
            event.preventDefault();
            return
        }
    }

    const validateUser = () => {
        console.log("Validating user...");
        if (!auth?.user) {
            console.log("User not authenticated. Signing in...");
            auth?.signInGoogle();
            console.log("User signed in.");
            return false;
        }

        console.log(videos)
        if (!auth.user?.product_id && videos.length >= 1) {
            console.log("User has reached the limit of free videos.");
            setUpgradeModal(true)
            return false;
        }

        console.log("User validated.");
        return true
    }

    const validateVideoDuration = (duration: number) => {
        console.log("Validating video duration...");
        if (!auth?.user) {
            console.log("User not authenticated.");
            alert("Please sign in to upload videos.")
            return false;
        }

        if (!auth.user.product_id) {
            if (duration > MAX_FREE_VIDEO_DURATION_SEC) {
                console.log("Video duration exceeds the limit for free users.");
                alert("Videos must be less than 20 minutes long, please upgrade your plan to upload longer videos.")
                return false;
            }
        }

        if (auth.user.product_id) {
            if (auth.user.current_period_uploaded_video_seconds + duration >= auth.user.current_period_max_video_seconds) {
                console.log("User has reached the monthly video limit.");
                alert("Cannot upload video, would exceed monthly limit.")
                return;
            }
        }

        console.log("Video duration validated.");
        return true;
    }

    const handleFileChange = async (files: FileList | null) => {
        setSelectedFiles(files);
    }

    const reset = () => {
        setSelectedFiles(null);
        setUploadPercentage(null);
        setUploadURL(null);
        setIsSigning(false);
        setFileName(null);
        setIsDragOver(false);
    }

    const writeFileRecord = async (source_id: string, object_key: string, source_filename: string) => {
        try {
            const response = await fetchUserAPI('/new-file', { source_id, object_key, source_filename }, 'POST');
            console.log("write file record response", response)
        } catch (error) {
            return null
        }
    }

    const createFilename = (source_filename: string) => {
        const source_id = generateId();
        const extension = source_filename.split('.').pop();
        const filename = `${auth?.user.user_id}/${source_id}.${extension}`
        return { filename, source_id }
    };

    const signURL = async (filename: string): Promise<any> => {
        try {
            setIsSigning(true);
            const response = await fetchUserAPI('/sign-upload', { name: filename }, 'POST');
            console.log("sign response", response)
            if (response.code === 200) {
                setUploadURL(response.data);
                return response.data;
            } else {
                onUploadError(`Failed to sign URL for ${filename}`);
                return null;
            }
        } catch (error) {
            // @ts-ignore
            onUploadError(error.message);
            return null
        } finally {
            setIsSigning(false);
        }
    };

    const getVideoDuration = (file: File): Promise<number> => {
        return new Promise((resolve, reject) => {
          const videoElement = document.createElement('video');
          videoElement.src = URL.createObjectURL(file);
      
          videoElement.onloadedmetadata = function() {
            URL.revokeObjectURL(videoElement.src);
            resolve(videoElement.duration);
          };
      
          videoElement.onerror = function() {
            reject('Failed to load video file');
          };
        });
    };

    const handleUpload = async (inputFile?: File) => {
        if (!validateUser()) {
            return
        }

        if (!selectedFiles && !inputFile) {
            onUploadError(`No file selected.`);
            return;
        }

        let uploadFile = inputFile || selectedFiles?.[0] || null;
        
        if (!uploadFile) {
            onUploadError(`No file selected.`);
            return;
        }        

        if (uploadFile.type.startsWith('video/')) {
            try {
                const duration = await getVideoDuration(uploadFile);

                if (!validateVideoDuration(duration)) {
                    return
                }

                console.log(`Video duration: ${duration} seconds`);
            } catch (error) {
                console.error(`Failed to get video duration: ${error}`);
                alert("Failed to get video duration, please try again.")
                return false;
            }
        }

        const names = createFilename(uploadFile.name)
        const uploadData = await signURL(names.filename);

        if (!uploadData) {
            onUploadError(`Failed to sign URL for ${uploadFile.name}`);
            return;
        }

        const formData = new FormData();
        if (uploadFile.size > maxSize) {
            onUploadError(`File ${uploadFile.name} is too large.`);
            return;
        }

        setFileName(uploadFile.name);

        Object.keys(uploadData.fields).forEach(key => {
            formData.append(key, uploadData.fields[key]);
        });

        formData.append('file', uploadFile);

        cancelTokenSource.current = axios.CancelToken.source();

        try {
            const response: AxiosResponse = await axios.post(uploadData.url, formData, {
                onUploadProgress: (progressEvent) => {
                    if (progressEvent.total) {
                        const percentage = progressEvent.loaded / progressEvent.total;
                        setUploadPercentage(percentage);
                    }
                },
                cancelToken: cancelTokenSource.current.token,
            });

            if (response.status === 204) {
                await writeFileRecord(names.source_id, names.filename, uploadFile.name);
                onUploadComplete();
            } else {
                onUploadError(`Upload failed with status: ${response.status}`);
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Upload cancelled');
                reset();
                alert('Upload cancelled');
            } else {
                // @ts-ignore
                onUploadError(error.message);
            }
        }
    };

    const cancelUpload = () => {
        if (cancelTokenSource.current) {
            cancelTokenSource.current.cancel('User cancelled the upload.');
        }
    };

    const uploading = useMemo(() => uploadPercentage !== null && uploadPercentage < 100, [uploadPercentage]);
    const upload_complete = useMemo(() => uploadPercentage === 1.0 ? true : false, [uploadPercentage]);

    useEffect(() => {
        if (upload_complete) setTimeout(() => reset(), 5000)
    }, [upload_complete])

    return (
        <div className={classNames({ "border border-slate-400 bg-white dark:bg-slate-800 rounded-xl h-full w-full": !upload_complete, "p-6": !small, "p-4": small })} style={{maxWidth: '100%', overflow: 'hidden'}}>
        { isSigning && <LoadingIndicator/>}
        {!isSigning && !uploading && !upload_complete && (
            <div 
                className={classNames("border-dashed h-full border-2 rounded-xl cursor-pointer flex items-center justify-center", { "border-blue-500 bg-blue-100 dark:bg-blue-800": isDragOver, "border-slate-400 dark:border-slate-600": !isDragOver, "flex-col p-12": !small, "p-2 flex-row items-center justify-center space-x-2": small })} 
                onDragOver={(e) => {
                    e.preventDefault();
                    setIsDragOver(true);
                }} 
                onDragLeave={() => setIsDragOver(false)}
                onDrop={handleFileDrop} 
                onClick={() => document.getElementById('file-upload')?.click()}
            >
                    <p className={classNames("text-center", { "text-blue-500": isDragOver, "text-slate-500 dark:text-slate-300": !isDragOver, "text-xs": small })}>
                        Accepted file types: {acceptedFileTypes.split(',').map(type => type.split("/")[1].trim()).join(', ')}
                    </p>
                <label htmlFor="file-upload" className="cursor-pointer">
                    <AiOutlineUpload className={classNames("h-6 w-6", { "text-slate-500 dark:text-slate-300": !isDragOver, "text-blue-500": isDragOver, "h-4 w-4": small })} />
                </label>
                <input 
                    id="file-upload" 
                    type="file" 
                    accept={acceptedFileTypes}
                    onClick={handleValidateUser}
                    multiple={false} 
                    onChange={(e) => {
                        const file = e.target.files?.[0] || null;
                        console.log(file)
                        if (file) handleUpload(file);
                    }} 
                    className="hidden" 
                />
            </div>
        )}

        {uploading && !upload_complete && (
                <div className="flex flex-col justify-center items-center">
                    <p className={classNames("text-slate-500 dark:text-slate-300 pb-2", { "text-sm": small })}>Uploading {fileName}...</p>
                    {uploadPercentage !== null && <ProgressIndicator percentage={uploadPercentage} />}
                    <button 
                        className={classNames("mt-4 px-4 py-2 bg-slate-200 dark:bg-slate-800 hover:bg-slate-300 dark:hover:bg-slate-700 text-slate-500 dark:text-slate-300 rounded-md", { "px-2 py-1 text-xs": small })} 
                        onClick={cancelUpload}
                    >
                        Cancel Upload
                    </button>
                </div>
        )}

        {upload_complete && (
        <div className="flex items-center justify-center p-5 bg-green-100 dark:bg-green-700 text-green-700 dark:text-green-200 rounded-md">
            <p className={classNames("mr-2", { "text-sm": small })}>Success, file uploaded</p>
            <AiFillCheckCircle className={classNames("h-6 w-6 text-green-500 dark:text-green-200", { "h-4 w-4": small })} />
        </div>
        )}
    </div>
    );
};

export default FileUploader;
import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ChatConversation } from '../types/chat';
import { generateId } from '../utilities';
import fetchUserAPI from '../services/userApi';

export const useChatMessages = () => {
    const [loading, setLoading] = useState(true);
    const [responseLoading, setResponseLoading] = useState(false);
    const [conversations, setConversations] = useState<Partial<ChatConversation>[]>([]);
    const [conversation, setConversation] = useState<ChatConversation | null>(null);
    const { source_id } = useParams();

    const conversation_id = useMemo(() => conversation?.conversation_id || generateId(), [conversation]);

    useEffect(() => {
        if (!source_id) {
            reset();
            return;
        };

        fetchConversations(source_id);
    }, [source_id]);
    
    const fetchConversations = async (source_id: string) => {
        setLoading(true);
        setConversation(null);
        const response = await fetchUserAPI("conversations", { source_id }, "GET");
        if (response.code == 200 && response.data && response.data.length > 0) {
            const sortedConversations = response.data.sort((a: any, b: any) =>
                new Date(b.conversation_created_at).getTime() - new Date(a.conversation_created_at).getTime());
            setConversations(sortedConversations);
            const mostRecentConversation = sortedConversations[0];
            setConversation(mostRecentConversation);
        }
        setLoading(false);
    };

    const selectConversation = async (conversation_id: string) => {
        setLoading(true);
        const selectedConversation = conversations.find((conversation: any) => conversation.conversation_id === conversation_id);
        if (selectedConversation) {
            const response = await fetchUserAPI("conversation", { source_id, conversation_id }, "GET");            
            if (response.code == 200) {
                setConversation(response.data || null);
            }
        }
        setLoading(false);
    };

    const reset = () => {
        setConversations([]);
        setConversation(null);
    }

    const createNewConversation = (): ChatConversation => {
        return {
            source_id: source_id || "",
            user_id: "test-user-id", // TODO get from auth
            conversation_id: generateId(),
            title: "New Conversation",
            conversation_created_at: new Date().toISOString().replace('T', ' ').substring(0, 19),
            source_type: "video", // TODO get from source data
            messages: [],
        }
    }

    const sendMessage = async (content: string) => {
        if (!source_id) return;

        const message: any = {
            source_id,
            conversation_id,
            message: {
                role: "user",
                content,
                timestamp: new Date().toISOString().replace('T', ' ').substring(0, 19),
            }
        }

        console.log("sending message", message);

        let shouldFetchConversations = conversation ? false : true;
        let newConversation = conversation ? { ...conversation } : createNewConversation();
        newConversation.messages.push(message.message);

        setConversation(newConversation);

        setResponseLoading(true);

        const response = await fetchUserAPI("chat", message, "POST");
        console.log(response)

        if (response.code === 200) {
            newConversation.messages.push(response.data);
            setConversation(newConversation);
        } else {
            alert("Error generating response...")
        }

        if (shouldFetchConversations) {
            await fetchConversations(source_id);
        }

        setResponseLoading(false)
        
    };

    return {
        conversations,
        conversation,
        selectConversation,
        loading,
        responseLoading,
        sendMessage,
        reset,
    };
};

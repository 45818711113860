import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import Layout from "./components/containers/Layout";
import HomePage from "./pages/HomePage";
import ConversationPage from "./pages/ConversationPage";
import React from "react";
import AuthProvider, { useAuth } from "./services/AuthProvider";
import PricingPage from "./pages/PricingPage";
import AccountPage from "./pages/AccountPage";
import LoadingView from "./components/LoadingView";

function AppRoot({children}: {children: React.ReactNode}) {
  
  return (<BrowserRouter>
        <AuthProvider>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
        <RecoilRoot>
        <Layout>
            {children}
            </Layout>
      </RecoilRoot>
      </QueryParamProvider>
      </AuthProvider>
      </BrowserRouter>
  );
}

function AppContent() {
  const auth = useAuth();

  if (auth?.loading) {
    return <LoadingView/>
  }

  return <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/account" element={<AccountPage />} />
                <Route 
                    path="/chat/:source_id" 
                    loader={({ params }) => Promise.resolve(params.source_id)} 
                    element={<ConversationPage/>} 
                    />
            </Routes>
}

function App() {
  return <AppRoot>
    <AppContent/>
  </AppRoot>
}

export default App;

import { FC } from 'react';

interface YouTubeIconProps {
  widthSize?: string;
}

export const YouTubeIcon: FC<YouTubeIconProps> = ({ widthSize = 'w-9' }) => {
  return (
    <img src='icons/youtube.svg' className={widthSize}/>
  );
};
export default YouTubeIcon;
import { FC } from 'react';
import PricingCard from './PricingCard';

interface PricingTableProps {}

export const PricingTable: FC<PricingTableProps> = () => {
  return (
      <div className="flex md:flex-row flex-col items-center justify-center md:space-x-4 md:space-y-0 space-y-6">
          <PricingCard 
              product_id='prod_Ous8vJH2F2Vhto'
              title={'Base'} 
              description={'For those that consistently need to chat with stuff to save time.'} 
              price={9.99} 
              billedFrequency={'Monthly'} 
              buttonText={'Subscribe'} 
              features={['Unlimited chats', 'Unlimited PDFs', '5 hours of video / month']}
          />
          <PricingCard 
              product_id='prod_Ous8sAzc1tLvrT'
              title={'Pro'} 
              description={'For pro users who have a medium amount of stuff to chat with.'} 
              price={19.99} 
              billedFrequency={'Monthly'} 
              buttonText={'Subscribe'} 
              features={['Unlimited chats', 'Unlimited PDFs', '10 hours of video / month']}
          />
            <PricingCard 
              product_id='prod_Ous8G98kcKRRRn'
              title={'Power'} 
              description={'For power users with stacks on stacks of stuff to chat with.'} 
              price={29.99} 
              billedFrequency={'Monthly'} 
              buttonText={'Subscribe'} 
              features={['Unlimited chats', 'Unlimited PDFs', '20 hours of video / month']}
          />
      </div>
  );
};

export default PricingTable;
import { FC } from 'react';
import { useAuth } from '../services/AuthProvider';
import { format } from 'date-fns';


interface UsageQuotaCardProps {
  
}

export const UsageQuotaCard: FC<UsageQuotaCardProps> = () => {
    const auth = useAuth();
    
    if (!auth?.user || !auth?.user.product_id) {
        return null;
    }
    
    const { 
        current_period_uploaded_video_seconds, 
        current_period_max_video_seconds, 
        renewal_date 
    } = auth?.user;
    
    const progress = (current_period_uploaded_video_seconds / current_period_max_video_seconds) * 100;
    
    return (
        <div className='text-center bg-white dark:bg-slate-800'>
            <p className="text-slate-800 dark:text-slate-400 text-xs text-left">Video Uploads</p>
            <div className="relative pt-1">
                <div className="overflow-hidden h-2 mb-2 flex rounded bg-slate-200 dark:bg-slate-700">
                    <div style={{ width: `${progress}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-convokiwi dark:bg-convokiwi-dark"></div>
                </div>
            </div>
            <div className="text-xs text-slate-500 dark:text-slate-300">{(current_period_uploaded_video_seconds / 3600).toFixed(2)} of {(current_period_max_video_seconds / 3600).toFixed(2)} hours</div>
            <div className="text-xs text-slate-400 dark:text-slate-200">Resets {format(new Date(renewal_date), 'MM/dd/yyyy')}</div>
        </div>
    );
};

export default UsageQuotaCard;
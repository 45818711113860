import React from 'react';
import { FC } from 'react';

interface TinyLoadingIndicatorProps {
  
}

export const TinyLoadingIndicator: FC<TinyLoadingIndicatorProps> = () => {
  return (
    <span className="animate-spin inline-block w-3 h-3 border-[1px] border-current border-t-transparent text-gray-500 rounded-full" role="status" aria-label="loading">
    <span className="sr-only">Loading...</span>
    </span>);
};

export default TinyLoadingIndicator;
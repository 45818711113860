import React from 'react';
import { ChatMessage } from '../../../types/chat';

interface InteractiveChatMessageProps {
    message: ChatMessage;
}

const InteractiveChatMessage: React.FC<InteractiveChatMessageProps> = ({ message }) => {
    return (
        <div className="bg-gray-300 p-2 rounded-lg">
            Interactive content placeholder: {JSON.stringify(message.content)}
        </div>
    );
}

export default InteractiveChatMessage;

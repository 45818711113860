import { FC } from 'react';
import PricingTable from '../components/PricingTable';

interface PricingPageProps {}

export const PricingPage: FC<PricingPageProps> = () => {
  return (
    <div className="mt-12 overflow-y-scroll h-full">
        <h2 className="w-full flex justify-center items-center my-4 mb-12 text-4xl font-bold dark:text-white">Pick your plan</h2>
        <PricingTable/>
    </div>
  );
};

export default PricingPage;
import { FC, useEffect, useState } from 'react';
import fetchUserAPI from '../services/userApi';
import LoadingIndicator from '../components/LoadingIndicator';
import SourceCard, { SourceData } from '../components/SourceCard';
import { useRecoilState } from 'recoil';
import { VideosState } from '../atoms';

interface SourcesCardAreaProps {}

export const SourcesList: FC<{ videos: SourceData[] }> = ({ videos }) => {
    return (
        <div className="flex flex-wrap justify-center">
            {videos.map((video) => (
                <SourceCard key={video.source_id} {...video} />
            ))}
        </div>
    );
};

export const SourcesCardArea: FC<SourcesCardAreaProps> = () => {
    const [sources, setSources] = useRecoilState(VideosState)
    const [loading, setLoading] = useState<boolean>(false);

    const fetchSources = async () => {
        try {
            const response = await fetchUserAPI('/videos');
            if (response.code == 200) {
                const sortedSources = response.data.sort((a: any, b: any) => {
                    const dateA = a.started_processing_at ? new Date(a.started_processing_at).getTime() : 0;
                    const dateB = b.started_processing_at ? new Date(b.started_processing_at).getTime() : 0;
                    return dateB - dateA;
                });
                setSources(sortedSources);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        setLoading(true)
        fetchSources().then(() => setLoading(false))
        const interval = setInterval(fetchSources, 4000);
        return () => clearInterval(interval);
    }, []);

    if (loading) return <LoadingIndicator />
    
    if (!loading && sources.length === 0) return (
        <div className="w-full flex flex-col items-center justify-center space-y-4 p-8 py-12 rounded-lg">
            <p className="text-xl dark:text-slate-400 text-slate-600">No uploads found</p>
            <p className="text-md dark:text-slate-400 text-slate-500">Please upload a file to get started</p>
        </div>
    );

    return (
        <div className="flex items-center justify-center flex-col w-full">
            <SourcesList videos={sources} />
        </div>
    );
};
export default SourcesCardArea;
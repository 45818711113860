import { atom } from 'recoil';

export const VideosState = atom({
  key: 'VideosState',
  default: [],
});

export const VideoTimestampState = atom({
  key: 'VideoTimestampState',
  default: 0,
});

export const citationLoadingState = atom<{id: string, loading: boolean}>({
  key: 'citationLoadingState',
  default: {id: '', loading: false},
});

import React from "react";
import { FC, createContext, useContext, ReactNode } from "react";
import useFirebaseAuth from "../hooks/useFirebaseAuth";

export interface AuthContextValue {
    user: any; // TODO type
    token: string | null;
    authState: any;
    loading: boolean;
    signUpEmailPassword: (email: string, password: string, data: any) => Promise<void>;
    signInEmailPassword: (email: string, password: string) => Promise<void>;
    sendPasswordReset: (email: string) => Promise<void>;
    signInGoogle: () => Promise<void>;
    signOut: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextValue | null>(null);

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const auth = useFirebaseAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextValue | null => useContext(AuthContext);

export default AuthProvider;
